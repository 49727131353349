import { all, call, takeEvery } from 'redux-saga/effects'

import { actions } from '../utils/constants'
import Axios from 'axios'

function *saveCookieConsent({ payload }) {
    try {
        if (document.cookie.includes('CookieConsent')) {
            try {
                const urlcustom = 'https://docs.api.scisapp.com';
                yield Axios.post(`${urlcustom}/v2/febracis-cookies/cookies/`,undefined, {headers: {Cookie: document.cookie}});
            }
            catch (error) {
                console.log(error);
            }
        }

    } catch(error) {
        yield call(error)
    }
}

export default function *MySaga() {
    yield all([
        takeEvery(actions.SAVE_COOKIE_CONSENT, saveCookieConsent),
    ])
}